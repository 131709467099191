export const getErrorPayload = (error) => {
    if (!error || typeof error != 'object')
        return internalError;
    const axiosError = error;
    if (axiosError.response)
        return { ...axiosError.response?.data, status: axiosError.response.status };
    else
        return internalError;
};
const internalError = {
    error: 'Internal error occured during request.',
    status: 500
};
