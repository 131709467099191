import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createInvoiceAsync, getUsersAcceptedDebtorsAsync, getFreelancerChecklistAsync, downloadInvoiceDocumentsAsync } from '@application/features/invoices/invoiceThunk';
import { getFreelancerInvoicePackagesAsync } from '@application/features/invoicePackages/invoicePackageThunks';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toggleCreateInvoiceModal } from '@application/features/invoices/invoiceSlice';
import FqButton from '@ui/components/FqButton';
import FqValidation from '@ui/components/FqValidation';
import { selectStyles } from '@ui/styles/select-style';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFreelancerPaymentPackagesAsync } from '@application/features/paymentPackages/paymentPackageThunks';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { translate } from '@application/common/helpers/translate';
import FqTooltip from '@ui/components/FqToolTip';
import { getCurrentUserDetailsAsync } from '@application/features/users/userThunks';
import i18n from '../../../../i18n';
import { APIStatus } from '@application/common/enums/api-status';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
const CreateInvoiceForm = ({ debtorsId }) => {
    const [selectedInvoicePackageId, setSelectedInvoicePackageId] = useState('');
    const [selectedInvoicePackageFee, setSelectedInvoicePackageFee] = useState(0);
    const [selectedChecklist, setSelectedChecklist] = useState([]);
    const [selectedPaymentPackageId, setSelectedPaymentPackageId] = useState('');
    const [selectedFixedFee, setSelectedFixedFee] = useState(0);
    const [payableInvoiceAmount, setPayableInvoiceAmount] = useState(0);
    const [selectedInvoiceAmount, setSelectedInvoiceAmount] = useState(0);
    const [activeTimesheetDocuments, setActiveTimesheetDocuments] = useState([]);
    const [deletedTimesheetDocuments, setDeletedTimesheetDocuments] = useState([]);
    const [documentTimesheetError, setDocumentTimesheetError] = useState('');
    const [selectedDetborExpireError, setselectedDetborExpireError] = useState('');
    const [documentInvoiceError, setDocumentInvoiceError] = useState('');
    const [invoiceAmountError, setInvoiceAmountError] = useState('');
    const dispatch = useAppDispatch();
    const { freelancerInvoicePackages } = useAppSelector((state) => state.invoicePackage);
    const { paymentPackages } = useAppSelector((state) => state.paymentPackage);
    const { freelancerDebtors, freelancerDebtorsList, freelancersInvoiceChecklist, status } = useAppSelector((state) => state.invoice);
    const { currentUser, currentUserDetail } = useAppSelector((state) => state.auth);
    const { handleSubmit, register, watch, setValue, control, formState: { errors } } = useForm({
        defaultValues: { invoiceDocument: null, timesheetDocuments: [], freelancersSelectedInvoiceChecklists: [] }
    });
    const vat = watch('vAT');
    const adjustedBalance = watch('adjustedBalance');
    const onSubmit = (invoice) => {
        if (selectedChecklist.length < freelancersInvoiceChecklist.length) {
            return;
        }
        else {
            invoice.freelancersSelectedInvoiceChecklists = [...selectedChecklist];
        }
        if (selectedDetborExpireError != '') {
            return;
        }
        invoice.paymentPackageId = selectedPaymentPackageId;
        invoice.invoicePackageId = selectedInvoicePackageId;
        invoice.invoiceAmount = selectedInvoiceAmount;
        dispatch(createInvoiceAsync(invoice));
    };
    const onChecklistChecked = (selectedChecklistId) => {
        if (selectedChecklist.includes(selectedChecklistId)) {
            const itemsList = selectedChecklist.filter((id) => id !== selectedChecklistId);
            setSelectedChecklist(itemsList);
        }
        else {
            const itemsList = [...selectedChecklist];
            itemsList.push(selectedChecklistId);
            setSelectedChecklist(itemsList);
        }
    };
    const onInvoiceAmountChange = (values) => {
        const invoiceAmount = values.floatValue ?? 0;
        setSelectedInvoiceAmount(invoiceAmount);
        if (invoiceAmount > 0) {
            const invoiceAmountWithFee = invoiceAmount - (invoiceAmount / 100) * (selectedInvoicePackageFee + selectedFixedFee);
            const invoiceAmountWithBalance = invoiceAmountWithFee + adjustedBalance;
            const invoiceAmountWithVAT = invoiceAmountWithBalance + (invoiceAmountWithBalance / 100) * vat;
            setPayableInvoiceAmount(Number(invoiceAmountWithVAT.toFixed(2)));
        }
        else {
            setPayableInvoiceAmount(0);
        }
        setInvoiceAmountError('');
    };
    const invoiceAmountValidation = () => {
        if (!selectedInvoiceAmount) {
            setInvoiceAmountError(translate('validations.fieldIsRequired'));
            return false;
        }
        if (selectedInvoiceAmount < 1) {
            setInvoiceAmountError(translate('validations.minimumInvoiceAmountRequired'));
            return false;
        }
        return true;
    };
    const onDebtorChange = (val) => {
        if (val !== '') {
            const debtor = freelancerDebtorsList.find((c) => c.debtorId === val);
            if (debtor?.contractEndDate != null && moment.utc().toDate() > moment(debtor?.contractEndDate).toDate()) {
                setselectedDetborExpireError(translate('validations.ExpiredDebtor'));
            }
            else {
                setselectedDetborExpireError('');
            }
            const elements = Array.from(document.getElementsByClassName('package__two-tab-active'));
            elements.map((element) => {
                element.classList.value = '';
                element.classList.add('package__two');
            });
            document.getElementById(debtor?.paymentPackage ? debtor?.paymentPackage.id : '')?.classList.add('package__two-tab-active');
            setSelectedPaymentPackageId(debtor?.paymentPackage ? debtor?.paymentPackage.id : '');
            setSelectedFixedFee(debtor?.fixedFee ? debtor?.fixedFee : 0);
        }
        else {
            setselectedDetborExpireError('');
        }
    };
    const onInvoicePackageChange = (event) => {
        const elements = Array.from(document.getElementsByClassName('tab-active'));
        elements.map((element) => {
            element.classList.value = '';
        });
        event.currentTarget.classList.add('tab-active');
        setSelectedInvoicePackageId(event.currentTarget.id);
        const invoicePackage = freelancerInvoicePackages.find((c) => c.id === event.currentTarget.id);
        setSelectedInvoicePackageFee(invoicePackage?.percentage ? invoicePackage?.percentage : 0);
    };
    useEffect(() => {
        dispatch(getFreelancerInvoicePackagesAsync());
        dispatch(getUsersAcceptedDebtorsAsync());
        dispatch(getFreelancerChecklistAsync());
        dispatch(getFreelancerPaymentPackagesAsync());
        dispatch(getCurrentUserDetailsAsync());
    }, [dispatch]);
    useEffect(() => {
        if (selectedInvoicePackageId === '' || selectedInvoicePackageId === undefined) {
            setSelectedInvoicePackageId(freelancerInvoicePackages[0]?.id);
            setSelectedInvoicePackageFee(freelancerInvoicePackages[0]?.percentage ? freelancerInvoicePackages[0]?.percentage : 0);
        }
    }, [freelancerInvoicePackages]);
    useEffect(() => {
        if (selectedInvoiceAmount > 0) {
            const invoiceAmountWithFee = selectedInvoiceAmount - (selectedInvoiceAmount / 100) * (selectedInvoicePackageFee + selectedFixedFee);
            const invoiceAmountWithBalance = invoiceAmountWithFee + adjustedBalance;
            const invoiceAmountWithVAT = invoiceAmountWithBalance + (invoiceAmountWithBalance / 100) * vat;
            setPayableInvoiceAmount(Number(invoiceAmountWithVAT.toFixed(2)));
        }
    }, [selectedInvoicePackageFee, selectedFixedFee]);
    useEffect(() => {
        if (vat < 0)
            setValue('vAT', 0);
        if (selectedInvoiceAmount > 0) {
            const invoiceAmountWithFee = selectedInvoiceAmount - (selectedInvoiceAmount / 100) * (selectedInvoicePackageFee + selectedFixedFee);
            const invoiceAmountWithBalance = invoiceAmountWithFee + adjustedBalance;
            const invoiceAmountWithVAT = invoiceAmountWithBalance + (invoiceAmountWithBalance / 100) * vat;
            setPayableInvoiceAmount(Number(invoiceAmountWithVAT.toFixed(2)));
        }
    }, [vat]);
    useEffect(() => {
        if (debtorsId !== null || debtorsId !== undefined) {
            onDebtorChange(debtorsId ? debtorsId : '');
            setValue('debtorId', debtorsId ? debtorsId : '');
        }
    }, [freelancerDebtorsList]);
    useEffect(() => {
        if (currentUserDetail)
            setValue('adjustedBalance', currentUserDetail.currentBalance);
    }, [currentUserDetail, setValue]);
    useEffect(() => {
        setValue('vAT', 21);
    }, [setValue]);
    const allowedExtension = ['pdf'];
    const allowedTimesheetExtension = ['pdf', 'jpg', 'jpeg', 'vnd.ms-excel', 'xls', 'xlsx', 'csv', 'txt'];
    const uploadedInvoiceFile = watch('invoiceDocument');
    const uploadInvoiceFile = (e) => {
        if (e.target.files && e.target.files.length) {
            const selectedExtension = e.target.files[0].name.split('.').pop();
            if (!selectedExtension) {
                setDocumentInvoiceError(translate('validations.onlyPDFAllowed'));
                return;
            }
            if (!allowedExtension.includes(selectedExtension)) {
                setDocumentInvoiceError(translate('validations.onlyPDFAllowed'));
                return;
            }
            setValue('invoiceDocument', e.target.files[0]);
            setDocumentInvoiceError('');
        }
        e.target.value = '';
    };
    const isValidInvoiceDocument = () => {
        if (!uploadedInvoiceFile) {
            setDocumentInvoiceError(translate('validations.fieldIsRequired'));
            return false;
        }
        return true;
    };
    const deleteInvoiceFile = (name) => {
        setValue('invoiceDocument', undefined);
    };
    const uploadedTimesheetFiles = watch('timesheetDocuments');
    const uploadTimesheetFile = (e) => {
        if (!e.target.files) {
            setValue('timesheetDocuments', []);
            return;
        }
        for (let index = 0; index < e.target.files.length; index++) {
            const element = e.target.files[index];
            const selectedExtension = element.name.split('.').pop();
            if (!selectedExtension) {
                setDocumentTimesheetError(translate('validations.onlyPDFAllowed'));
                return;
            }
            if (!allowedTimesheetExtension.includes(selectedExtension)) {
                setDocumentTimesheetError(translate('validations.requiredTimesheetDocuments'));
                return;
            }
            if (!uploadedTimesheetFiles.map((x) => x.name).includes(element.name)) {
                uploadedTimesheetFiles.push(element);
            }
        }
        setValue('timesheetDocuments', [...uploadedTimesheetFiles]);
        setDocumentTimesheetError('');
    };
    const deleteTimesheetFiles = (name) => {
        setValue('timesheetDocuments', [...uploadedTimesheetFiles.filter((x) => x.name !== name)]);
        isValidTimesheetDocuments();
    };
    const downloadTimesheetDocuments = (document) => {
        const fileDto = { folderName: document?.folderName, fileName: document?.name };
        dispatch(downloadInvoiceDocumentsAsync(fileDto))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>TimesheetDocument-${currentUser?.name}</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    const deleteTimesheetDocument = (document) => {
        const deletedIds = deletedTimesheetDocuments.filter((x) => x !== document.id);
        deletedIds.push(document.id);
        setDeletedTimesheetDocuments(deletedIds);
        setActiveTimesheetDocuments(activeTimesheetDocuments.filter((a) => a.id !== document.id));
    };
    const isValidTimesheetDocuments = () => {
        if (!uploadedTimesheetFiles.length && !activeTimesheetDocuments.length) {
            setDocumentTimesheetError(translate('validations.fieldIsRequired'));
            return false;
        }
        return true;
    };
    return (_jsxs("form", { className: 'invoice__form', onSubmit: handleSubmit(onSubmit), noValidate: true, children: [_jsx("div", { className: 'row', children: _jsx("div", { className: 'col-lg-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('invoice.selectDebtor') }), _jsx(Controller, { name: 'debtorId', control: control, rules: {
                                    required: {
                                        value: true,
                                        message: translate('validations.fieldIsRequired')
                                    }
                                }, render: ({ field: { onChange, value } }) => {
                                    const customOnChange = (element) => {
                                        onChange(element?.value ? element?.value : '');
                                        onDebtorChange(element?.value ? element?.value : '');
                                    };
                                    return (_jsx(Select, { placeholder: translate('invoice.selectDebtor'), isSearchable: false, isClearable: true, styles: selectStyles, value: freelancerDebtors.find((c) => c.value === value), options: freelancerDebtors, onChange: (e) => customOnChange(e) }));
                                } }), selectedDetborExpireError && (_jsx(FqValidation, { fieldError: { type: 'debtor', message: translate(selectedDetborExpireError) } })), _jsx(FqValidation, { fieldError: errors.debtorId })] }) }) }), _jsx("div", { className: 'row', children: _jsx("div", { className: 'col-lg-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('invoice.selectPackage') }), _jsx("div", { className: 'tabs', children: _jsx("ul", { className: 'tabs-nav', children: freelancerInvoicePackages.map((item, index) => {
                                        if (item.paymentDays > 1) {
                                            return (_jsx("li", { id: item.id, onClick: onInvoicePackageChange, className: `${index === 0 ? 'tab-active' : ''}`, children: _jsxs("div", { className: 'package', children: [_jsx("h4", { children: item.title }), _jsx("div", { dangerouslySetInnerHTML: {
                                                                __html: translate('invoice.invoicePackageDescription', {
                                                                    paymentDays: item.paymentDays,
                                                                    percentage: item.percentage.toLocaleString('nl')
                                                                })
                                                            } })] }, item.id) }, index));
                                        }
                                        else {
                                            return (_jsx("li", { id: item.id, onClick: onInvoicePackageChange, className: `${index === 0 ? 'tab-active' : ''}`, children: _jsxs("div", { className: 'package', children: [_jsx("h4", { children: item.title }), _jsx("div", { dangerouslySetInnerHTML: {
                                                                __html: translate('invoice.oneDayinvoicePackageDescription', {
                                                                    paymentDays: item.paymentDays,
                                                                    percentage: item.percentage.toLocaleString('nl')
                                                                })
                                                            } })] }, item.id) }, index));
                                        }
                                    }) }) })] }) }) }), _jsx("div", { className: 'row', children: _jsx("div", { className: 'col-lg-12', children: _jsxs("div", { className: ' form-group', children: [_jsxs("label", { children: [translate('invoice.invoiceAmountExcludingVAT'), _jsx(FqTooltip, { content: translate('invoice.minimumInvoiceAmount') })] }), _jsx(Controller, { name: 'invoiceAmount', control: control, rules: {
                                    validate: invoiceAmountValidation
                                }, render: () => (_jsx(NumericFormat, { thousandSeparator: '.', decimalSeparator: ',', onValueChange: onInvoiceAmountChange })) }), invoiceAmountError && _jsx(FqValidation, { fieldError: { type: 'invoiceAmount', message: invoiceAmountError } })] }) }) }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-4', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('invoice.packageFee') }), _jsx("input", { className: 'disabled', value: selectedInvoicePackageFee.toLocaleString('nl', {
                                        maximumFractionDigits: 2
                                    }), disabled: true })] }) }), _jsx("div", { className: 'col-lg-4', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('invoice.fixedFee') }), _jsx("input", { className: 'disabled', value: selectedFixedFee.toLocaleString('nl', {
                                        maximumFractionDigits: 2
                                    }), disabled: true })] }) }), _jsx("div", { className: 'col-lg-4', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('common.balance') }), _jsx(Controller, { name: 'adjustedBalance', control: control, render: ({ field: { onChange, value } }) => (_jsx(NumericFormat, { disabled: true, className: 'disabled', value: value, thousandSeparator: '.', decimalSeparator: ',', onValueChange: (values) => {
                                            onChange(values.floatValue);
                                        } })) })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-4', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('invoice.vat') }), _jsx(Controller, { name: 'vAT', control: control, rules: {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        }
                                    }, render: ({ field: { onChange, value } }) => (_jsx(NumericFormat, { value: value, thousandSeparator: '.', decimalSeparator: ',', onValueChange: (values) => {
                                            onChange(values.floatValue);
                                        } })) }), _jsx(FqValidation, { fieldError: errors.vAT })] }) }), _jsx("div", { className: 'col-lg-8', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('invoice.payableInvoiceAmount') }), _jsx(NumericFormat, { disabled: true, thousandSeparator: '.', decimalSeparator: ',', value: payableInvoiceAmount })] }) })] }), _jsx("div", { className: 'row', children: _jsxs("div", { className: 'form-group cussor-pointer', children: [_jsx("label", { htmlFor: 'invoice-file-upload', children: _jsxs("div", { className: 'uplode__file', children: [_jsx("div", { className: 'uplode__file__left', children: _jsx("label", { htmlFor: 'invoice-file-upload', style: { padding: 0 }, children: _jsx(FontAwesomeIcon, { icon: 'cloud-arrow-up', color: '#FF0000' }) }) }), _jsxs("div", { className: 'uplode__file__right cussor-pointer', children: [_jsxs("label", { htmlFor: 'invoice-file-upload', className: 'mt-2', children: [translate('invoice.uploadInvoicePDF'), _jsx(FqTooltip, { content: translate('invoice.uploadInvoicePDFInfoText') })] }), _jsx(Controller, { name: 'invoiceDocument', control: control, rules: {
                                                    validate: isValidInvoiceDocument
                                                }, render: () => (_jsx("input", { accept: 'application/pdf', type: 'file', id: 'invoice-file-upload', hidden: true, onChange: uploadInvoiceFile })) })] })] }) }), documentInvoiceError && _jsx(FqValidation, { fieldError: { type: 'invoiceDocuments', message: documentInvoiceError } }), _jsx("div", { children: _jsx("ul", { className: 'list-group', children: uploadedInvoiceFile && (_jsxs("li", { className: 'list-group-item', children: [_jsx("span", { className: 'medium-font', children: uploadedInvoiceFile.name }), _jsx(FontAwesomeIcon, { className: 'pull-right', color: '#FF0000', icon: 'remove', onClick: () => deleteInvoiceFile(uploadedInvoiceFile.name) })] }, uploadedInvoiceFile.name)) }) })] }) }), _jsx("div", { className: 'row', children: _jsxs("div", { className: 'form-group cussor-pointer', children: [_jsx("label", { htmlFor: 'timesheet-file-upload', children: _jsxs("div", { className: 'uplode__file', children: [_jsx("div", { className: 'uplode__file__left', children: _jsx("label", { htmlFor: 'timesheet-file-upload', style: { padding: 0 }, children: _jsx(FontAwesomeIcon, { icon: 'cloud-arrow-up', color: '#FF0000' }) }) }), _jsxs("div", { className: 'uplode__file__right cussor-pointer', children: [_jsxs("label", { htmlFor: 'timesheet-file-upload', className: 'mt-2', children: [translate('invoice.uploadTimesheets'), _jsx(FqTooltip, { content: translate('invoice.uploadTimesheetsInfoText') })] }), _jsx(Controller, { name: 'timesheetDocuments', control: control, rules: {
                                                    validate: isValidTimesheetDocuments
                                                }, render: () => (_jsx("input", { multiple: true, accept: 'image/jpg,image/jpeg,image/png,application/pdf,application/vnd.ms-excel, .xls, .xlsx, .csv', type: 'file', id: 'timesheet-file-upload', hidden: true, onChange: uploadTimesheetFile })) })] })] }) }), documentTimesheetError && (_jsx(FqValidation, { fieldError: { type: 'timesheetDocuments', message: documentTimesheetError } })), _jsx("div", { children: _jsxs("ul", { className: 'list-group', children: [uploadedTimesheetFiles &&
                                        uploadedTimesheetFiles.map((x) => (_jsxs("li", { className: 'list-group-item', children: [_jsx("span", { className: 'medium-font', children: x.name }), _jsx(FontAwesomeIcon, { className: 'pull-right', color: '#FF0000', icon: 'remove', onClick: () => deleteTimesheetFiles(x.name) })] }, x.name))), activeTimesheetDocuments &&
                                        activeTimesheetDocuments?.map((x) => (_jsxs("li", { className: 'list-group-item', children: [_jsx("span", { className: 'medium-font', onClick: () => downloadTimesheetDocuments(x), children: `${x.name.split('-')[0]}...${x.name.split('.')[x.name.split('.').length - 1]}` }), _jsx(FontAwesomeIcon, { className: 'pull-right', color: '#FF0000', icon: 'remove', onClick: () => deleteTimesheetDocument(x) })] }, x.id)))] }) })] }) }), _jsx("div", { className: 'row', children: _jsxs("div", { className: 'col-lg-12', children: [_jsxs("div", { className: 'form-group', children: [_jsx("label", { children: _jsx("strong", { children: translate('invoice.invoiceCheckList') }) }), freelancersInvoiceChecklist.map((item, index) => {
                                    return (_jsxs("div", { className: 'form-check', children: [_jsx("input", { className: 'form-check-input', id: `invoice-checklist-${index}`, type: 'checkbox', ...register(`freelancersSelectedInvoiceChecklists.${index}`, {
                                                    onChange(event) {
                                                        onChecklistChecked(item.id);
                                                    },
                                                    required: true
                                                }) }), _jsx("label", { className: 'form-check-label', htmlFor: `invoice-checklist-${index}`, style: { marginTop: '2.5px' }, children: i18n.language === 'nl' ? item.dutchTitle : item.title })] }, index));
                                })] }), errors.freelancersSelectedInvoiceChecklists && (_jsx("div", { style: { fontSize: '0.875em', color: '#dc3545', marginTop: '0.25rem' }, children: translate('validations.invoiceChecklistRequired') }))] }) }), _jsxs("div", { className: 'd-flex justify-content-end', children: [_jsx(FqButton, { mode: 'danger', type: 'button', onClick: () => dispatch(toggleCreateInvoiceModal()), children: translate('common.cancel') }), _jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', isDisabled: status === APIStatus.PENDING, children: translate('common.save') })] })] }));
};
export default CreateInvoiceForm;
