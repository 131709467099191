import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    responsiveMenuOpen: false,
    userLanguageSwitched: false
};
export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        toggleResponsiveMenu(state) {
            state.responsiveMenuOpen = !state.responsiveMenuOpen;
        },
        toggleUserLanguage(state) {
            state.userLanguageSwitched = !state.userLanguageSwitched;
        }
    }
});
export const { toggleResponsiveMenu } = layoutSlice.actions;
export default layoutSlice.reducer;
