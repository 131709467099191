import { createSlice, isRejected } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
export const notificationSlice = createSlice({
    name: 'app/notification',
    initialState: {},
    reducers: {
        addSuccessNotification(_, action) {
            toast.success(action.payload);
        },
        addErrorNotification(_, action) {
            toast.error(action.payload);
        },
        addWarningNotification(_, action) {
            toast.warn(action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(isRejected, (_, action) => {
            if (action.meta.rejectedWithValue && action.type !== 'auth/refreshToken/rejected') {
                const apiError = action.payload;
                if (apiError.status === 400 || apiError.status === 404) {
                    if (apiError.error)
                        toast.error(apiError.error);
                    if (apiError.validationErrors)
                        apiError.validationErrors.map((error) => {
                            toast.error(error);
                        });
                }
            }
        });
    }
});
export const { addSuccessNotification, addErrorNotification, addWarningNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
